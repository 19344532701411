.delegateDash {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  background-color: #c9c8c8;
  padding: 1rem;
  box-sizing: border-box;
  gap: 1rem;
  overflow: auto;
  padding-bottom: 20px;

  .notifications, .personalNotifications {
    background-color: #3D4832;
    border-radius: 10px;
    padding: 1rem;
    height: 95%;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 4px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }

    h2 {
      color: white;
      margin: 0 0 1rem 0;
      padding: 0 0.5rem;
      position: sticky;
      top: 0;
      background-color: #3D4832;
      z-index: 1;
    }

    .stackbox-container {
      height: auto;
    }

    .stackbox {
      padding: 0.5rem;
    }
  }

  .notifications {
    flex: 3;
  }

  .personalNotifications {
    flex: 2;
  }

  @media (max-width: 950px) {
    padding: 0.5rem;
    gap: 0.5rem;
    
    flex-direction: column;
    
    .notifications, .personalNotifications {
      height: calc(50vh - 1rem);
      padding: 0.5rem;

      h2 {
        font-size: 1.2rem;
        margin: 0 0 0.5rem 0;
        padding: 0 0.25rem;
      }

      .stackbox-container {
        min-height: 0;
      }

      .notification-card {
        .MuiCardContent-root {
          padding: 0.75rem;
        }

        .notification-header {
          font-size: 1rem;
          margin-bottom: 0.25rem;
        }

        .notification-content {
          font-size: 0.9rem;
          
          h1 { font-size: 1.2em; }
          h2 { font-size: 1.1em; }
          h3 { font-size: 1em; }

          p {
            margin: 0.5em 0;
            line-height: 1.4;
          }

          ul, ol {
            margin: 0.5em 0;
            padding-left: 1.5em;
            
            li {
              margin: 0.2em 0;
            }
          }
        }

        .notification-link {
          font-size: 0.9rem;
          margin: 0.25rem 0;
        }

        .timestamp {
          font-size: 0.75rem;
          margin-top: 0.25rem;
        }

        .year-badge {
          font-size: 0.7rem;
          padding: 1px 6px;
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    gap: 0.25rem;

    .notifications, .personalNotifications {
      padding: 0.25rem;
      margin-bottom: 0.5rem;

      h2 {
        font-size: 1.1rem;
      }

      .notification-card {
        .MuiCardContent-root {
          padding: 0.5rem;
        }

        .notification-header {
          font-size: 0.95rem;
        }

        .notification-content {
          font-size: 0.85rem;
        }
      }
    }
  }
}

// Card Styling
.notification-card {
  background-color: white;
  margin-bottom: 0.5rem !important;
  width: 100%;
  box-sizing: border-box;
  
  .MuiCardContent-root {
    padding: 1rem;
    height: auto;
    overflow: visible;
  }

  .year-badge {
    display: inline-block;
    background-color: #f5f5f5;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .notification-header {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 0.5rem;
  }

  .notification-content {
    margin: 1rem 0;
    overflow: visible;
    height: auto;
    width: 100%;
    
    // Headers
    h1, h2, h3 {
      margin: 1em 0 0.5em 0;
      line-height: 1.2;
      color: #333;
    }
    
    h1 { font-size: 1.5em; }
    h2 { font-size: 1.3em; }
    h3 { font-size: 1.1em; }

    // Paragraphs
    p {
      margin: 0.8em 0;
      line-height: 1.5;
      overflow-wrap: break-word;
      white-space: pre-wrap;
    }

    // Lists
    ul, ol {
      margin: 0.8em 0;
      padding-left: 2em;
      
      li {
        margin: 0.3em 0;
        line-height: 1.4;
      }
    }

    // Colored text and backgrounds
    span[style*="color"] {
      display: inline-block;
      padding: 0.1em 0;
    }

    span[style*="background-color"] {
      display: inline-block;
      padding: 0.1em 0.3em;
    }

    // Empty paragraphs for spacing
    p:empty, p:blank {
      min-height: 1em;
    }

    // Remove extra spacing from first and last elements
    > *:first-child {
      margin-top: 0;
    }
    
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .notification-link {
    display: block;
    color: #1976d2;
    text-decoration: none;
    word-break: break-word;
    margin: 0.5rem 0;
    
    &:hover {
      text-decoration: underline;
    }
  }

  .timestamp {
    color: #666;
    font-size: 0.8rem;
    margin-top: 0.5rem;
  }
}
