.basic-date-calendar{
  display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 20px;
    height: 100%;
  background-color: #3D4832; 
  padding:15px;
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #3D4832;
  }

  h3 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #3D4832;
  }

.basic-date-calendar-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

 
.travel-flight-no{
  width: 100%;
  .travel-flight-no-input{
    width: 100%;
  }
}
  .date-time-picker {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: #3D4832;

    .MuiTextField-root {
      margin: 10px 0;
    }
  }

  .document-upload {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    input[type="file"] {
      margin-bottom: 10px;
      color: #3D4832;
    }
    input[type="file"]::file-selector-button {
      background-color: #3D4832; // Nature green color
      color: #fff;
       padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      border-radius: 5px; // Rounded corners for buttons
      box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
      transition: background-color 0.3s; // Smooth transition for hover effect

      &:hover {
        background-color: #388e3c; // Darker green on hover
      }
    }

    .upload-button {
      background-color: #3D4832; // Nature green color
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      border-radius: 5px; // Rounded corners for buttons
      box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
      transition: background-color 0.3s; // Smooth transition for hover effect

      &:hover {
        background-color: #388e3c;
      }
    }
  }

  .submit-section {
    margin-top: 20px; // Adds space above the submit button
  }

  .submitBut {
    background-color: #3D4832; // Nature green color
    color: #fff;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px; // Rounded corners for buttons
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
    transition: background-color 0.3s; // Smooth transition for hover effect

    &:hover {
      background-color: #388e3c; // Darker green on hover
    }
    &:disabled{
      background-color: #777777; 
    }

  }

  .document-list {
    margin-top: 20px;
    width: 100%;

    .MuiListItem-root {
      border-bottom: 1px solid #ddd;
      padding: 10px;
    }
  }
}
}