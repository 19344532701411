.signup{
 
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;

 
    .leftbox{
        position: relative;
        width: 50%;
        background-color: #3D4832;
        height: 100%;
         align-items: center;
        align-content: center;
        text-align: center;
        z-index: 2;
        @media (max-width: 1500px) {
            width: 40%}
        .image{
            top: 30%;
            position: relative;
            height: 20rem;
            width:20rem;
            z-index: 2;
            border-radius: 160px;
            
        }
     
}

    .signupBox{
        display: flex;
        align-items: flex-end;
        width: 50%;
        z-index: 1;
        height: 100%;
        background-color: #3d483238;
        .signupInnerBox{
            position: absolute;
             margin: 8rem;
             padding: 3rem 2rem;
             right: 1rem;
             border-radius: 10px;
                background-color: rgb(239, 239, 252);
            .signupinnerinner {
                display: flex;
                flex-direction: column;
                
                .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
                    color: #3D4832;
                }
                .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
                    color: #3D4832;
                }
                .MuiTypography-root.MuiTypography-caption.css-1sn4lm3-MuiTypography-root{
                    padding-bottom: 10px;
                }
                .MuiBox-root{
                    padding: 2px;
                    display: flex;
                    flex-direction: column;
                    .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
                        padding: 10px; 
                    }
                }
            }
            
            display: flex;
            
        }
        


}
.leftbox::after{
    content: "";
    z-index: 1;
    position: absolute;
     left:80% ;
    bottom: 0;
    top: 0;
    width: 40%;
    background-color: #3D4832;
    transform: skewX(-15deg);
    @media (max-width: 1500px) {
        width: 50%;
        left:70% ;
        transform: skewX(-15deg);
    }
}
@media (max-width: 1100px) {
  
    .leftbox{
       display: none;
        .image{
           
       display: none;
            
        }
     
}

}

@media (max-width: 950px) {
    flex-direction: column;
    
    .leftbox {
        display: flex;
        width: 100%;
        height: 200px;
        justify-content: center;
        align-items: center;
        
        .image {
            display: block;
            position: static;
            height: 100px;
            width: 100px;
            top: 0;
        }
    }

    .signupBox {
        width: 100%;
        min-height: calc(100% - 200px);
        background-color: #3d483238;
        align-items: center;
        justify-content: center;

        .signupInnerBox {
            position: relative;
            margin: 1rem;
            right: 0;
            width: 90%;
            max-width: 400px;
        }
    }

    .leftbox::after {
        display: none;
    }
}
}