.paymentPlan {
  display: flex;
  flex-direction: row;
  padding: 5px;
  align-items: center;
  justify-content: center;
  .css-ahj2mt-MuiTypography-root{
    color: #3d4832;
  }
  .chkForm {
    // display: inline;
    // padding: 5px;
    .css-jsexje-MuiSwitch-thumb{
      color: #3d4832;
    }
  }
}
.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin: 20px;
  height: 100%;
  background-color: #3d4832;
  padding: 15px;
  .payment-container-inner {
    border-radius: 15px;

    background-color: #f0f5f1; // Light green background for a nature theme
    padding: 15px;
  }

  .payment-title {
    align-self: center;
    margin: 5px;
    font-size: 24px;
    color: #3d4832;
    // margin-bottom: 20px;
  }

  .content-block {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }

  .form-control {
    margin-bottom: 15px;
    width: 100%;

    &.dropdown {
      width: 50%; // Make the dropdown smaller
    }

    &.upload-section {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input[type="file"] {
        margin-bottom: 10px;
        color: #3d4832;
      }
      input[type="file"]::file-selector-button {
        background-color: #3d4832; // Nature green color
        color: #fff;
        padding: 5px 10px;
        border: none;
        cursor: pointer;
        font-size: 12px;
        border-radius: 5px; // Rounded corners for buttons
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        transition: background-color 0.3s; // Smooth transition for hover effect

        &:hover {
          background-color: #388e3c; // Darker green on hover
        }
      }

      .upload-button {
        background-color: #3d4832; // Nature green color
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-size: 12px;
        border-radius: 5px; // Rounded corners for buttons
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        transition: background-color 0.3s; // Smooth transition for hover effect

        &:hover {
          background-color: #388e3c;
        }
      }
    }

    .MuiButton-containedSuccess {
      background-color: #3d4832; // Nature green color
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 12px;
      border-radius: 5px; // Rounded corners for buttons
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
      transition: background-color 0.3s; // Smooth transition for hover effect

      &:hover {
        background-color: #388e3c; // Darker green on hover
      }
      &:disabled {
        background-color: #9e9e9e;
      }
    }
  }
}
