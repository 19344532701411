.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  .topbar {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 74px;
    z-index: 1001;
  }

  .main-content {
    display: flex;
    // margin-top: 75px;
    height: calc(100vh - 80px);
    width: 100%;

    // .sidebar {
    //   width: 280px;
    //   min-width: 280px;
    //   height: 95%;

    //   @media screen and (max-width: 950px) {
    //     display: none;
        
    //     &.mobile-open {
    //       display: block;
    //       position: fixed;
    //       top: 74px;
    //       left: 0;
    //       width: 100%;
    //       height: calc(100vh - 74px);
    //       z-index: 1000;
    //     }
    //   }
    // }

    .content {
      flex: 1;
      // padding: 20px;
      overflow-y: auto;
      
      @media screen and (max-width: 950px) {
        width: 100%;
      }
    }
  }
}

// Scrollbar styling
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(141, 173, 241);
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-track:hover {
  background: #555;
}