.floatbuttoncomp{
    right: 2.5rem;
    bottom: 2.5rem;
    z-index: 100;
    position: fixed;
    
.floatbuttoncomp1{

    display: flex;
    flex-direction: column;
    align-items: flex-end;
        .floatbutton{

            .fab {
                color:#3D4832 ;
                background-color:#c9c8c8 ;
                    margin: 5px;
                    box-shadow: 0px 0px 5px 2px #5f5f5f;
               }
        }
        .floatbuttonclicked{
            // position: fixed;
           display: flex;
           flex-direction: column;
           align-items: flex-end;
           .fab {
            color:#3D4832 ;
            background-color:#c9c8c8 ;
                margin: 5px;
                margin-right: 13px;
                box-shadow: 0px 0px 5px 2px #5f5f5f;
           }

           .floatbuttonclickedUser{
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            .floatbuttonclickedUSerInner{
                img{
                    height:28px;
                    width:28px;
                    color:#3D4832 ;
                }
            }
           }
        }
}}