.profile-contract{
    background-color: #3D4832; 
    // display: flex;
    // align-items: center;
    // align-content: center;
    // left: 50%;
    // Dark green background for the whole page
    padding: 10px;
    height: 100%;
    .profile-contract-inner{
        display: flex;
        flex-direction: column;
        align-items: center; // Center children horizontally
        max-width: 800px;
        height: 100%;
        margin: auto;
        padding: 20px;
        // gap: 20px;
        background-color: #f0f5f1; 
        border-radius: 15px;
        overflow: auto;

        .contractDown{
            // border-style: groove;
            // border-color:#3D4832 ;
            outline: 1px solid #3D4832;
            border-radius: 15px;
            padding: 15px;
            margin-top: 10px;
        align-items: center; // Center children horizontally
        text-align: center;


        }
        h1{
            font-size: 30px;
            // margin-bottom: 20px;
            color: #3D4832;
            align-self: center;
        }
        h2{
            font-size: 24px;
            margin-bottom: 10px;
            color: #3D4832;
            text-align: center;
        }
        a{
            color: #3D4832;
        }
        .upload{
            display: flex;
            flex-direction: column;
            .contract{
                padding-top: 25px;
            }
            .button{
                background-color: #3D4832;
                color: #fff;
                padding: 10px 20px;
                border: none;
                cursor: pointer;
                font-size: 16px;
                border-radius: 5px;
                box-shadow: 0 4px 8px rgba(0,0,0,0.1);
                transition: background-color 0.3s;

                &:hover {
                background-color: #388e3c;
                }

                &:disabled {
                background-color: #9e9e9e;
                }
            }
            input[type="file"] {
                margin-bottom: 10px;
                color: #3D4832;
              }
              input[type="file"]::file-selector-button {
                background-color: #3D4832; // Nature green color
                color: #fff;
                 padding: 5px 10px;
                border: none;
                cursor: pointer;
                font-size: 12px;
                border-radius: 5px; // Rounded corners for buttons
                box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
                transition: background-color 0.3s; // Smooth transition for hover effect
            
                &:hover {
                  background-color: #388e3c; // Darker green on hover
                }
              }
        }
    }
}