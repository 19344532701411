html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
   display: flex;
   flex-direction: column;
}
.app1 {
  display: flex;
  position: relative;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(141, 173, 241);
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-track:hover {
  background: #555;
}