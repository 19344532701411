.notifications{
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin: 20px;
    height: 100%;
  background-color: #3D4832; 
  padding:15px;


    .notifications-inner{
            width: 500px;
            background-color: #f5f5f5;
            border-radius: 15px;
            padding: 10px;
            align-items: center;
           
        h1{
            font-size: 24px;
            margin-bottom: 20px;
            color: #3D4832;
        }
        .textarebox{
            .TextField {
                // padding: auto;
                margin-top: 10px;
                font-weight: bold;
                width: 100%;
                // height: 100px;
                min-height: 100px;
                max-width: 494px; // Ensure label takes full width
                text-align: center; // Center the label text
              }
              
        }
        .select{
            .selectIn{
                height: 50px;
                width: 100%;

            }
        }
        .buttonbox{
            button{
                margin-top: 15px;
                background-color: #3D4832; // Nature green color
                color: #fff;
                padding: 10px 10px;
                border: none;
                cursor: pointer;
                font-size: 12px;
                border-radius: 5px; // Rounded corners for buttons
                box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
                transition: background-color 0.3s; // Smooth transition for hover effect
              
                &:hover {
                  background-color: #388e3c; // Darker green on hover
                }
            }
        }
    }
}

.rich-text-editor{
    min-height: 300px;
    margin-bottom: 20px;
    
    .ql-container {
        height: calc(100% - 42px);
        min-height: 250px;
        font-size: 16px;
        background: white;
        border-radius: 0 0 4px 4px;
    }
    
    .ql-toolbar {
        background: #f8f8f8;
        border-radius: 4px 4px 0 0;
    }
    
    .ql-editor {
        min-height: 250px;
        
        &.ql-blank::before {
            font-style: normal;
            color: #999;
        }
    }
}

   