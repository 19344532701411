.profile-medical{
background-color: #3D4832; 
// display: flex;
// align-items: center;
// align-content: center;
// left: 50%;
// Dark green background for the whole page
padding: 10px;
height: 100%;
// min-height: 20rem;
.buttonder{
  display: flex
 
}


.profile-medical-container {
  display: flex;
  flex-direction: column;
  align-items: center; // Center children horizontally
  max-width: 800px;
  height: 100%;
  margin: auto;
  padding: 20px;
  gap: 20px;
  background-color: #f0f5f1; 
  border-radius: 15px;
  overflow: auto;


  .section-title {
    margin-bottom: 20px;
    text-align: center;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    align-items: center; // Center the label and textarea
    width: 100%; // Set width to 100% to fill the container
.uploadButton{
  background-color: #3D4832; // Nature green color
  color: #fff;
  padding: 10px 10px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  border-radius: 5px; // Rounded corners for buttons
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
  transition: background-color 0.3s; // Smooth transition for hover effect

  &:hover {
    background-color: #388e3c; // Darker green on hover
  }

  
}
    .input-label {
      margin-bottom: 10px;
      font-weight: bold;
      width: 100%; // Ensure label takes full width
      text-align: center; // Center the label text
    }

    textarea {
      width: 100%; // Full width of the input-section
      min-height: 150px;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 16px;
      resize: vertical;
    }
  }

  input[type="file"] {
    margin-bottom: 10px;
    color: #3D4832;
  }
  input[type="file"]::file-selector-button {
    background-color: #3D4832; // Nature green color
    color: #fff;
     padding: 5px 10px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    border-radius: 5px; // Rounded corners for buttons
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); // Subtle shadow for depth
    transition: background-color 0.3s; // Smooth transition for hover effect

    &:hover {
      background-color: #388e3c; // Darker green on hover
    }
  }
  .upload-button, .submit-button {
    background-color: #3D4832;
    color: #fff;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: background-color 0.3s;

    &:hover {
      background-color: #388e3c;
    }

    &:disabled {
      background-color: #9e9e9e;
    }
  }

  // .document-list {
  //   margin-top: 5px;

    .list-item {
      margin-bottom: 10px;
    }
  // }
}
}

.profile-medical-container ::-webkit-scrollbar {
  width: 11px;
}
.profile-medical-container {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.profile-medical-container::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  border-radius: 6px;
}
.profile-medical-container::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}