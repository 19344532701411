.page-container {
  background-color: #3D4832; 
  align-items: center;
  align-content: center;
  padding: 10px;
  height: 100%;
}

.profile-personal-container {
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  height: 90%;
  margin: auto;
  padding: 20px;
  width: 80%;
  overflow: auto;
  gap: 20px;
  background-color: #f0f5f1;

  .personal-info-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
    color: #333;
  }

  .content-section {
    display: flex;
    justify-content: space-between;
    gap: 40px;

    .left-side, .right-side {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .MuiTextField-root {
        width: 100%;
        margin-bottom: 15px;
        background-color: #3d483218;
        border-radius: 5px;
      }
    }

    .right-side {
      input[type="file"] {
        margin-bottom: 10px;
        color: #3D4832;
      }

      input[type="file"]::file-selector-button {
        background-color: #3D4832;
        color: #fff;
        padding: 5px 10px;
        border: none;
        cursor: pointer;
        font-size: 12px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        transition: background-color 0.3s;

        &:hover {
          background-color: #388e3c;
        }
      }

      .profile-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
        margin-bottom: 10px;
        border: 3px solid #4caf50;
      }

      button.submit-button {
        background-color: #3D4832;
        color: white;
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }

  .submit-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .submit-button {
      background-color: #3D4832;
      color: #fff;
      padding: 10px 20px;
      border: none;
      cursor: pointer;
      font-size: 16px;
      border-radius: 5px;
      box-shadow: 0 4px 8px rgba(0,0,0,0.1);
      transition: background-color 0.3s;
      min-width: 200px;

      &:hover {
        background-color: #388e3c;
      }

      &:disabled {
        background-color: #cccccc;

      color: #666666;
        
        cursor: not-allowed;
      }
    }

    .submit-button-disabled {
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;


      &:hover {
        background-color: #cccccc;
      }
    }
  }

  // Mobile styles
  @media (max-width: 950px) {
    width: 95%;
    padding: 15px;
    gap: 15px;

    .content-section {
      flex-direction: column;
      gap: 20px;

      .left-side, .right-side {
        width: 100%;

        .MuiTextField-root {
          margin-bottom: 10px;
        }
      }

      .right-side {
        .profile-image {
          width: 80px;
          height: 80px;
          margin: 10px auto;
          display: block;
        }

        input[type="file"] {
          width: 100%;
          margin: 8px 0;
        }
      }
    }

    .submit-button-container {
      margin-top: 20px;
      
      .submit-button {
        width: 100%;
        padding: 12px;
      }
    }
  }
}

// Scrollbar styles
.profile-personal-container {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  &::-webkit-scrollbar {
    width: 11px;
  }

  &::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }
}

// MUI overrides
.MuiAlert-root {
  margin-top: 10px;
}

label {
  color: #3D4832;
}