.Sidebar {
  height: 100%;
  background-color: #3D4832;

  .ps-sidebar-root {
    height: 100%;
    border: none !important;
    width: 270px;
  }

  .ps-sidebar-container {
    background-color: #3D4832 !important;
    color: white;
  }

  .ps-collapsed {
    width: 80px !important;
    min-width: 80px !important;
  }

  .ps-menu-root {
    height: 100%;
  }

  .ps-menu-button {
    padding: 5px 35px 5px 20px !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .ps-active {
    background-color: rgb(140, 141, 141) !important;
    border-radius: 15px;
  }

  .ps-menu-label {
    overflow: visible !important;
  }

  .ps-collapsed .ps-menu-label {
    opacity: 0;
  }
}

// Mobile styles
@media screen and (max-width: 950px) {
  .Sidebar {
    // display: none;  // Hide by default on mobile
    position: fixed;
    top: 74px;
    left: 0;
    // width: 90%;
    height: calc(100vh - 74px);
    z-index: 1000;
    background-color: #3D4832;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);

    // Make sure sidebar content takes full width on mobile
    .ps-sidebar-root,
    .ps-sidebar-container {
      width: 100% !important;
    }
    .collapsed {
      display: none;
    }
    // Always show menu labels on mobile
    .ps-collapsed {
      width: 100% !important;
      min-width: 100% !important;
      display: none;
      .ps-menu-label {
        opacity: 1;
      }
    }

    // Adjust padding for mobile
    .ps-menu-button {
      padding: 5px 20px !important;
    }
  }
}

// Show sidebar when navbar menu button is clicked (using a global class)
body.menu-open {
  @media screen and (max-width: 950px) {
    .Sidebar {
      display: block;
    }
  }
}