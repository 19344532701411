.login{
 
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
 
    .leftbox{
        position: relative;
        width: 50%;
        background-color: #3D4832;
        height: 100%;
         align-items: center;
        align-content: center;
        text-align: center;
        z-index: 2;
        @media (max-width: 1500px) {
            width: 40%}
        .image{
            top: 30%;
            position: relative;
            height: 20rem;
            width: 20rem;
            z-index: 2;
            border-radius: 160px;
            
        }
     
}

    .loginBox{
        display: flex;
        align-items: flex-end;
        width: 50%;
        height: 100%;
        background-color: rgb(239, 239, 252);
        .loginInnerBox{
            position: relative;
            padding: 10rem; 
            display: flex;  
        }
        


}
.leftbox::after{
    content: "";
    z-index: 1;
    position: absolute;
     left:80% ;
    bottom: 0;
    top: 0;
    width: 40%;
    background-color: #3D4832;
    transform: skewX(-15deg);
   
}
@media (max-width: 1500px) {
    .leftbox{
        width: 40%
    }
    .leftbox::after{
    width: 50%;
    left:70% ;
    transform: skewX(-15deg);
}

.loginBox{
    width: 60%;
    
}
}

}
@media (max-width: 1030px) {
    .loginInnerBox{
            
        padding: 5rem !important; 
        
    }
    .leftbox{
    // display: none;
    
       }
        .login{
        // flex-direction: ;
        }
        .leftbox::after{
            display: none;
        }
       
    }
    @media (max-width: 836px) {
        
        .loginInnerBox{
                
            padding: 5rem !important; 
            
        }
        .leftbox{
        // display: none;
            height: 30% !important;
            width: 100% !important;
            // align-items: center;
            .image{
                top: 10% !important;
               position: relative;
            //    align-self: center;
               height: 10rem !important;
               width: 10rem !important;
               z-index: 2;
               border-radius: 160px;   
           } 
    }
            .login{
             flex-direction:column ;
            //  align-content: center;
             .loginBox{
                position: relative;
                // left: 0%;
                height: 70%;
                width: 100%;
                text-align: center;
                .loginInnerBox{
                    position: relative;
                    // left: 15%;
                    

                }
             }
            }
            .leftbox::after{
                display: none;
            }
           
        }
@media (max-width: 500px) {
        
            .loginInnerBox{
                    
                padding: 5rem !important; 
                
            }
            .leftbox{
            // display: none;
                height: 30% !important;
                width: 100% !important;
                // align-items: center;
                .image{
                    top: 10% !important;
                   position: relative;
                //    align-self: center;
                   height: 10rem !important;
                   width: 10rem !important;
                   z-index: 2;
                   border-radius: 160px;   
               } 
        }
                .login{
                 flex-direction:column ;
                //  align-content: center;
                 .loginBox{
                    position: relative;
                    align-items: flex-start;

                    // left: 0%;
                    height: 70%;
                    width: 100%;
                    text-align: center;
                    .loginInnerBox{
                        position: relative;
                        // left: 15%;
                        padding-left : 5px !important;
                        padding-right : 5px !important;

                        
    
                    }
                 }
                }
                .leftbox::after{
                    display: none;
                }
               
            }