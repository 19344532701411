.navbarBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px !important;
  background-color: #3D4832;
  height: 74px;

  .leftSection {
    display: flex;
    align-items: center;
    padding-left: 10px;

    .menuButton {
      color: white;
      margin-right: 10px;
      
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .title {
      color: white;
      margin-left: 10px;
    }
  }

  .logo {
    border-radius: 3px;
    display: flex;
    
    img {
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }

  .rightBox {
    display: flex;
    align-items: center;
    
    button {
      padding: 10px;
      width: 7rem;
      height: 1.8rem;
      margin: 5px;
      
      .innerText {
        color: aliceblue;
        margin-right: 8px;

        @media screen and (max-width: 950px) {
          display: none;
        }
      }
      
      .innerButton {
        color: aliceblue;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
