.forgot-password {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
  
    .leftbox {
      position: relative;
      width: 50%;
      background-color: #3D4832;
      height: 100%;
      align-items: center;
      align-content: center;
      text-align: center;
      z-index: 2;
  
      @media (max-width: 1500px) {
        width: 40%;
      }
  
      .image {
        top: 30%;
        position: relative;
        height: 20rem;
        width: 20rem;
        z-index: 2;
        border-radius: 160px;
      }
    }
  
    .forgotPasswordBox {
      display: flex;
      align-items: flex-end;
      width: 50%;
      height: 100%;
      background-color: rgb(239, 239, 252);
  
      .forgotPasswordInnerBox {
        position: relative;
        padding: 10rem;
        display: flex;
      }
    }
  
    .leftbox::after {
      content: "";
      z-index: 1;
      position: absolute;
      left: 80%;
      bottom: 0;
      top: 0;
      width: 40%;
      background-color: #3D4832;
      transform: skewX(-15deg);
  
      @media (max-width: 1500px) {
        width: 50%;
        left: 70%;
        transform: skewX(-15deg);
      }
    }
  
    @media (max-width: 1100px) {
      .leftbox {
        display: none;
  
        .image {
          display: none;
        }
      }
    }
  }
  